.bottom-toolbar {
    position: absolute;
    bottom: 0;
    
    background-color: #f8f9fa; /* Light gray background */
    padding: 10px 0;
    display: flex;
    justify-content: space-evenly;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1); /* subtle shadow for some depth */
  }
  
  .toolbar-button {
    padding: 8px 16px;
    background-color: #007bff; /* Bootstrap primary blue */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .toolbar-button:hover {
    background-color: #0056b3;
  }
  