 
 

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
 }
 


/* 
 .sticky-column {
  height: 100vh;
  overflow: hidden;
}

.sticky-column .sticky {
  position: sticky;
  top: 0;
}

.scrollable-column {
  height: 100vh;
  overflow-y: auto;
} */

.scroll-container {
  height: 100vh;
  overflow-y: scroll;
}

.sticky-column {
  height: 100vh;
}

.sticky-column .sticky {
  position: sticky;
  top: 0;
}

.scrollable-column {
  height: 200vh;
  overflow: hidden;
}

.scrollable-content {
  overflow-y: visible;
}


.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 10;
  right: 10;
  height: 20vh;
  width: 48%;
  background: linear-gradient(to bottom, transparent, white);
  pointer-events: none;
  /* opacity: 0; */
  transition: opacity 0.3s ease;
  /* z-index: 5; */
}

/* .scrollable-column.scrolled .gradient-overlay {
  opacity: 1;
} */