@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.banner-wrapper {
  width: 100%;
  height: 10rem;
  overflow: hidden;
}

.banner-wrapper .wrapper {
  display: flex;
}

.banner-wrapper .wrapper .images {
  display: flex;
  /* animation: swipe var(--speed) linear infinite; */
}

.banner-wrapper:hover .wrapper .images {
  animation-play-state: paused;
}

@keyframes swipe {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}


.pulsating-dot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  animation: pulse 2s infinite;
  display: inline-block; /* Align inline with text */
  margin-right: 8px; /* Space between the dot and the text */
  vertical-align: middle; /* Center vertically with text */
}
.pulsating-dot-black {
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
  animation: pulse 2s infinite;
  display: inline-block; /* Align inline with text */
  margin-right: 8px; /* Space between the dot and the text */
  vertical-align: middle; /* Center vertically with text */
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.7;
  }
}

.text-with-dot {
  font-size: 1rem; /* 16px */
  font-weight: 600; /* Semibold */
  color: #1f2937; /* Gray-900 */
  padding-top: 20px; /* 5 Tailwind pt */
  display: flex;
  align-items: center; /* Ensures vertical alignment */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.how-it-works-background {
  background-image:  url('images/09.png'); 
    /*From public folder */
  background-size: cover; /* Cover the entire area of the div */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center; /* Center the background image */
}
